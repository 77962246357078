.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  -webkit-user-drag: none;
}

.row {
  display: flex;
  flex-wrap: wrap;

  flex-direction: column;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 540px;
  width: 100%;
}

.profile {
  width: 100%;
  padding-top: 2.5rem;
  text-align: center;
  overflow-wrap: break-word;
  height: auto;
}

.profile-image {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: top;
  text-align: center;
}

.user-photo {
  width: 122px;
  min-width: 122px;
  height: 122px;
  min-height: 122px;
  background-size: cover;
  display: block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.user-photo--fakeBorder {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border: 7px solid rgba(214, 214, 214, 0.4);
  width: 122px;
  min-width: 122px;
  height: 122px;
  min-height: 122px;
}

.default-photo {
  font-size: 100px;
  color: #162728;
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

.profile-name {
  color: #162728;
  text-align: center;
  width: 100%;
  font-family: proxima-nova, Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 26px;
  line-height: 1;
  margin-top: 20px;
  margin-bottom: 20px;
}

.bio {
  max-width: 75%;
  margin: 0 auto;
  min-height: 21px;
  text-align: center;
  margin-bottom: 30px;
  line-height: 21px;
  font-family: proxima-nova, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  overflow-wrap: break-word;
  white-space: pre-line;
}

.add-to-contact-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out;
}

.add-to-contact-button {
  width: 50%;
  margin-left: 8.7%;
  margin-right: 8.7%;
  border-radius: 5px;
  padding: 4px;
  background-color: #1b1b1b;
  border-color: #79ffb6;
  font-size: 16px;
  font-family: proxima-nova, Arial, Helvetica, sans-serif;
  font-weight: normal;
  overflow: hidden;
  color: white;
  cursor: pointer;
}

.add-to-contact-button i {
  font-weight: 900;
}

.social-form {
  padding-bottom: 20px;
}

.grid {
  text-align: center;
  padding-top: 1px;
  flex-direction: row !important;
  width: 100%;
  display: block;
}

@media screen and (min-width: 768px) {
  .grid {
    width: 540px;
    max-width: 540px;
  }
}

@media screen and (max-width: 767px) {
  .row {
    width: 100%;
  }
}

.container--notFound {
  padding: 1rem;
}
