.banner-wrapper {
  padding: 0 0;
  position: relative;
  z-index: 999999999;
  background: linear-gradient(90.79deg, #4bb7ba 19.12%, #309497 95.76%);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.banner-wrapper:hover {
  cursor: pointer;
}

.content-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.cta {
  margin: 5px 0 5px 0;
  color: #fff;
}
.cta-img {
  margin-left: 10px;
  height: 20px;
  width: 20px;
}
