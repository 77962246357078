body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #ffffff;
  background: url('/site-bg.png') center top no-repeat;
  background-size: cover;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@media only screen and (max-width: 812px) {
  body {
    background: url('/site-bg--mobile.png') center top no-repeat;
    background-size: contain;
    background-position-y: 95px;
  }
}

#root {
  height: 100%;
}

b,
strong {
  font-weight: bolder;
}

/* Tailwindish Utility classes */

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}
.mv-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.text-white {
  color: #c7c7c7;
}
