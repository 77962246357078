.grid-square {
  width: 33.3333%;
  display: inline-flex;
  flex-direction: column;
  padding: 0px;
  min-height: 125px;
  color: black;

  text-align: center;
  align-items: center;
}

.social {
  font-family: proxima-nova, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #162728;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.social--label {
  white-space: nowrap;
  margin-top: 5px;
  font-size: 0.79rem;
}

.social--logo-wrapper {
  width: 100%;
  height: 100%;
  max-width: 75px;
  min-width: 75px;
  max-height: 74px;
  min-height: 74px;
  box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
}

.social--logo-wrapper.is-circle{
  border-radius: 40px !important;
}

.social--logo {
  max-width: 75px;
  min-width: 75px;
  max-height: 75px;
  min-height: 75px;
  object-fit: contain;
}
