.header-wrapper {
  padding: 0 0;
  position: relative;
  z-index: 999999999;
  background: #fff;
}

.main-container {
  width: 100%;
  max-width: 570px;
  margin: 0 auto;
  display: table;
  padding: 10px 0;
}

.main-col {
  width: 33.3%;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.login-nav {
  margin-left: 20px;
}

.join-nav {
  margin-right: 20px;
  padding-left: 10px;
}

.img-logo {
  width: 160px;
  height: 40px;
  padding: 1px;
  vertical-align: middle;
}
