.ContactCardPopup {
  position: fixed;
  z-index: 900;
  background: linear-gradient(90.79deg, #4bb7ba 19.12%, #309497 95.76%);
  color: #ffffff;
  text-align: center;
  display: flex;
  flex: 1;

  flex-direction: column;
  min-height: 100px;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  top: -200px;
  left: 0;
  right: 0;
  border-radius: 1rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 1rem;
  padding-top: 2rem;
  z-index: 999999999;
  transition: all 400ms ease-in-out;
}

.ContactCardPopup a {
  margin-top: 0.5rem;
  display: inline-block;
  background-color: #ffffff;
  padding: 0.6rem 2rem;
  color: #309497;
  border-radius: 1rem;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}

.ContactCardPopup.visible {
  top: 0;
}
